<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="breadcrumb-bg"
      style="background-image: url(../assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">會員中心</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                我的 FQ 年齡
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-6 py-md-8">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-lg-4">
            <div class="card bg-warning card-hover mb-3">
              <div class="card-body text-center px-md-5 px-lg-6 my-2">
                <div class="card-icon-border-large border-warning mtn-80">
                  <!-- <img src="@/assets/img/avator/avator-img1.jpg" alt="testimonial1.jpg" v-if="reloginImg === 'null'"> -->
                  <img :src="reloginImg" alt="" v-if="!form.icon">
                  <img :src="form.icon" alt="">
                </div>
                <blockquote class="blockquote blockquote-sm mt-2">
                  <!-- <label class="btn btn-link">
                    <input id="upload_img" style="display: none" type="file" />
                    <i class="fa fa-plus-circle"></i> <span>上傳圖片</span>
                  </label> -->

                  <footer
                    class="blockquote-footer text-uppercase text-white font-size-20 mb-3"
                  >
                    {{ gradeTitle }}
                  </footer>
                  <ul class="member__level-buttons">
                    <li v-for="(item, index) in gradeMap" :key="index" class="m-1">
                      <button @click="$router.push(item.link)" :disabled="form.grade < item.grade" type="button" class="btn btn-light mb-1">
                        {{ `${item.title}測驗` }}
                      </button>
                    </li>
                  </ul>
                </blockquote>
              </div>
            </div>

            <ul class="list-unstyled mb-0">
              <li class="mb-2">
                <router-link to="/member"
                  class="text-muted font-weight-medium d-block border rounded py-2 pl-3">
                  基本資料
                </router-link>
              </li>
              <li class="my-2">
                <router-link :to="grade === 0? '/fqtest' : '/member/myfqage'"
                  class="text-warning font-weight-medium d-block border rounded py-2 pl-3">
                  我的FQ年齡
                </router-link>
              </li>
              <li class="my-2">
                <router-link to="/member/mypoints"
                  class="text-muted font-weight-medium d-block border rounded py-2 pl-3">
                  我的$幣存摺
                </router-link>
              </li>
              <li class="my-2">
                <router-link to="/member/mall"
                  class="text-muted font-weight-medium d-block border rounded py-2 pl-3">
                  $幣兌換中心
                </router-link>
              </li>
              <li class="my-2">
                <router-link to="/member/exchange"
                  class="text-muted font-weight-medium d-block border rounded py-2 pl-3">
                  $幣累兌明細
                </router-link>
              </li>
              <li class="my-2">
                <button type="button"
                  @click="logOut"
                  class="btn btn-block text-muted font-weight-medium  border rounded py-2 pl-3">
                  會員登出
                </button>
              </li>
            </ul>
          </div>
          <div class="col-md-8 col-lg-8 order-md-1">
            <div class="row">
              <div class="col-md-10 mx-auto">
                <div v-if="grade === 1">
                  <img src="@/assets/img/avator/kindergarten.jpg" alt="理財幼幼級" class="img-fluid">
                  <p class="fz-28">別擔心，你的理財知識目前是幼幼級，我相信身為一張白紙的你，多挑戰FQ大冒險，很快就升級了。</p>
                </div>
                <div v-if="grade === 2">
                  <img src="@/assets/img/avator/elementary.jpg" alt="理財國小級" class="img-fluid">
                  <p class="fz-28">恭喜你！你已經不是FQ小白囉！你是可愛的小學生了，記帳、算錢、單利跟複利的題目都難不倒你了。</p>
                </div>
                <div v-if="grade === 3">
                  <img src="@/assets/img/avator/junior.jpg" alt="理財國中級" class="img-fluid">
                  <p class="fz-28">哇！不錯哦！你已經有基本的理財觀念，你的金融素養應該有PR75的水準了。</p>
                </div>
                <div v-if="grade === 4">
                  <img src="@/assets/img/avator/senior.jpg" alt="理財高中級" class="img-fluid">
                  <p class="fz-28">好棒棒！你的實力可以準備去參加「金融奧林匹克」大賽了！</p>
                </div>
                <div v-else>
                  <p class="fz-28">你似乎還沒完成FQ測驗喔！
                    <router-link to="/fqtest">
                    點此測驗
                    </router-link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import tokenGenerator from '@/tokenGenerator.js'
import richkidConfig from '@/assets/json/richkid.config.json'
export default {
  name: 'myfqage',
  data () {
    return {
      memberToken: '',
      grade: '',
      reloginImg: '',
      gradeTitle: '',
      form: {}
    }
  },
  created () {
    const vm = this

    if (localStorage.getItem('memberToken') === '') {
      alert('請先登入')
      vm.$router.push('/login')
      return false
    }

    vm.getMember()
    vm.reloginImg = localStorage.getItem('reloginImg')
    vm.gradeTitle = sessionStorage.getItem('gradeTitle')
  },
  mounted () {
    require('@/assets/js/base.js')
  },
  computed: {
    gradeMap () {
      return richkidConfig.data.grade
    }
  },
  methods: {
    getMember () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/check`
      vm.memberToken = localStorage.getItem('memberToken')

      vm.axios.post(api, {
        token: tokenGenerator(),
        memberToken: vm.memberToken
      })
        .then((res) => {
          vm.form = res.data.content
          vm.grade = res.data.content.grade
        })
    },
    logOut () {
      localStorage.removeItem('token')
      localStorage.removeItem('memberId')
      localStorage.removeItem('memberToken')
      localStorage.removeItem('reloginImg')

      location.href = '/'
    }
  }
}
</script>

<style lang="scss" scoped>
  .fz-28 {
    font-size: 28px;
  }
  .member__level-buttons {
    padding-left: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 1rem 0;
  }
</style>
